.color-range-slider {
  width: 1015px;
  margin-left: -13px;
}
.clarity-range {
  margin-left: -1px;
}
.rc-slider-mark-text-active {
  color: #005fc1;
  font-weight: 700;
}

.rc-slider-handle,
.rc-slider-handle:hover {
  border-color: #005fc1;
}
.rc-slider-handle-click-focused:focus {
  border-color: #005fc1;
}
.rc-slider-handle:after {
  position: absolute;
  display: block;
  content: "";
  width: 24px;
  height: 24px;
  background: #005fc1d1;
  border-radius: 50%;
  z-index: 0;
  top: -7px;
  left: -7px;
  opacity: 0.5;
}
.order-list {
  box-shadow: none;
  border: none;
}
