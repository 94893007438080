@charset "UTF-8";

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto Slab", serif;
}

.container {
  max-width: 1200px;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #000;
  color: #fff;
  height: 91px;
  z-index: 9999999;
}

.dummy-div {
  height: 91px;
}

.logo {
  display: inline-block;
}

/* .logo-container {
  width: 21%;
} */
.logo-container {
  width: 200px;
  position: absolute;
  top: 0;
}

.logo-curency-menu-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.account-nav-wrapper {
  width: 100%;
  margin-left: 18%;
}

/* top curency account */
.account-currency {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.account-currency .currency select {
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  padding-right: 9px;
}

.vertical-line {
  width: 1px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 5px;
  display: inline-block;
  margin: 0px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.account span:nth-of-type(1) {
  margin-right: 9px;
}

.account button {
  background: transparent;
  color: #ffff;
  border: none;
}

.dropdown-content {
  display: none;
}

.shows {
  display: block !important;
}

.account button:focus {
  outline: none;
}

.account .dropdown-content {
  background-color: #000;
  position: absolute;
  z-index: 55;
  border-radius: 5px;
  top: 30px;
  right: -5px;
}

.account .dropdown-content a {
  transition: all 0.5s ease;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.02em;
}

.account .dropdown-content a:hover {
  background-color: #fff;
  color: #000;
}

.account-currency .currency select option:hover {
  background-color: #ffff !important;
}

/*  */
.menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu-wrapper {
  width: 75%;
}

.navbar-collapse {
  width: 100%;
}

.navbar {
  padding: 14px 0px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: #000;
}

.navbar-nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.navbar-nav .nav-link {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  position: relative;
}

.navbar-nav .nav-link {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0;
}

.navbar-nav .nav-link::after {
  position: absolute;
  content: " ";
  width: 0%;
  height: 2px;
  /* background-color: #005fc1; */
  background-color: #63a2e2;
  bottom: -8px;
  left: 0px;
}

.navbar-nav .nav-link:hover::after {
  width: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* active class of navbar nav */
a.active {
  position: relative;
  text-decoration: none;
  /* color: #005fc1 !important; */
  color: #63a2e2 !important;
}

a.active::after {
  position: absolute;
  content: " ";
  width: 100% !important;
  height: 2px;
  background-color: #e8ffff;
  bottom: -8px;
  left: 0px;
}

/* cross icon change nevagation */
.navbar-toggle {
  position: relative;
  outline: none !important;
  border: none !important;
}

.navbar-toggle .icon-bar {
  background-color: #ffffff !important;
  width: 20px;
  height: 2px;
}

.navbar-toggle .icon-bar:nth-of-type(1) {
  top: -2px;
}

.navbar-toggle .icon-bar:nth-of-type(2) {
  top: 2px;
}

.navbar-toggle .icon-bar:nth-of-type(3) {
  top: 6px;
}

.navbar-toggle .icon-bar {
  position: relative;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.navbar-toggle.active .icon-bar:nth-of-type(1) {
  top: -1.5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navbar-toggle.active .icon-bar:nth-of-type(2) {
  display: none;
}

.navbar-toggle.active .icon-bar:nth-of-type(3) {
  top: -3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.wishlist-cart-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 14px 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: flex-start;
}

.wishlist a,
.cart a {
  text-decoration: none;
  display: flex;
}

.cart button {
  display: flex;
}

.wishlist span:nth-of-type(1),
.cart span:nth-of-type(1) {
  margin-right: 7px;
}

.wishlist a {
  color: #fff;
}

.cart,
.cart-span {
  color: #005fc1;
}

.cart-count-image-wrapper {
  position: relative;
}

.count {
  top: 4px;
  position: absolute;
  left: 8px;
  font-size: 15px;
  color: #005fc1;
}

.cartclose {
  cursor: pointer;
}

/* Carousel */
.carousel-container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
}

.carousel-wrapper {
  background-color: #000;
  color: #fff;
}

.cmn-slider {
  padding: 10% 15px 18% 15px;
  background-image: url("../images/backgroundimage/slider1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.cmn-slider1 {
  padding: 19% 15px 18% 15px;
  background-image: url("../images/backgroundimage/process-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.cmn-slider2 {
  padding: 19% 15px 18% 15px;
  background-image: url("../images/backgroundimage/sale-Banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.cmn-inner-slider {
  max-width: 710px;
  margin-left: auto;
}

.cmn-inner-slider span:nth-of-type(1) {
  font-size: 28px;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.05em;
  -webkit-text-stroke: 0.3px #fff;
  margin-bottom: 6px;
}

.cmn-inner-slider h1 {
  font-size: 41px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.02em;
  margin-bottom: 14px;
}

.cmn-inner-slider p {
  font-size: 14.5px;
  line-height: 24px;
  margin-bottom: 35px;
  max-width: 85%;
  color: #ffffffcf;
  text-align: justify;
}

.cmn-inner-slider a {
  border-radius: 5px;
  padding: 12.5px 31px;
  background-color: #005fc1;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 14px;
}

.cmn-inner-slider a:hover {
  background-color: transparent;
  border: 1px solid #fff;
}

.carousel-container .slick-prev,
.carousel-container .slick-next {
  top: 46%;
}

.carousel-container .slick-prev {
  left: 2%;
}

.carousel-container .slick-next {
  right: 2%;
}

.carousel-container
  .multiple-items.slick-initialized.slick-slider.slick-dotted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.carousel-container .slick-arrow {
  font-size: 14px;
  border: 1px solid #ffffff !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 18px;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 55;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: transparent;
  border: none;
}

.carousel-container .slick-prev {
  background-image: url("../images/backgroundimage/back-arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.carousel-container .slick-next {
  background-image: url("../images/backgroundimage/right-arrow2.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.carousel-container .slick-arrow:hover {
  border-color: #005fc1 !important;
}

.carousel-container .slick-prev:before,
.carousel-container .slick-next:before {
  display: none;
}

.carousel-container .fa-arrow-left,
.fa-arrow-right {
  -webkit-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
}

.carousel-container .slick-dots {
  bottom: 15%;
}

.carousel-container .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fff;
  border: 1px solid transparent;
  background-color: #fff;
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  top: 6px;
  left: 6.3px;
}

.carousel-container .slick-dots li button:before {
  font-family: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  content: "";
  border: 1px solid #fff;
  text-align: center;
  opacity: 1;
  color: black;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.carousel-container li.slick-active button {
  background-color: #005fc1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.carousel-container li {
  margin: 0 10px !important;
}

.cmn-section-title {
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0.02em;
}

/* Home  About us */
.home-aboutus {
  padding-bottom: 5%;
}

.cmn-section-titile {
  font-size: 42px;
  font-weight: 900;
  line-height: 55px;
  letter-spacing: 0.02em;
  position: relative;
  margin-bottom: 0;
  /*  -webkit-text-stroke: 1px #005fc1; */
}

.cmn-diamond-with-title-wrapper {
  margin-bottom: 18px;
}

.cart {
  position: relative;
}

.go-to-cart {
  position: absolute;
  right: 0;
  display: none;
  top: 32px;
}

.shows1 {
  display: block !important;
}

a {
  cursor: pointer;
}

.empty-cart {
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #005fc1;
  color: #fff;
  font-size: 20px;
  margin-top: 24px;
}

.wishlist-close {
  color: #787878;
  font-size: 18px;
  cursor: pointer;
}

.wishlist-image {
  text-align: center;
}

.wishlist-image img {
  max-width: 236px;
  max-height: 185px;
}

.certilogo {
  max-width: 50px;
  display: inline-block;
}

/* media query start */
@media (min-width: 1100px) {
  .mt-home-aboutus-image {
    margin-top: -110px;
  }
}

.cmn-color-title {
  color: #005fc1;
}

.cmn-sub-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.02em;
  margin-bottom: 11px;
}

.cmn-peragraph {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #787878;
  text-align: justify;
  margin-bottom: 32px;
}

.cmn-learnmore-btn-grey a,
.cmn-learnmore-btn-grey button {
  border-radius: 5px;
  padding: 11px 29.5px;
  background-color: #333333;
  color: #fff !important;
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cmn-learnmore-btn-grey a:hover {
  background-color: transparent;
  border: 1px solid #333333;
  color: #333333 !important;
}

.pay-now-btn {
  background-color: rgba(0, 95, 193, 1) !important;
}

.pay-now-btn:hover {
  background: transparent !important;
  color: #005ec1 !important;
  border: 1px solid #005ec1 !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.cmn-diamond-with-title-wrapper {
  position: relative;
  margin-top: 50px;
}

.cmn-diamond-below-title {
  max-width: 282px;
  /* width: fit-content; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 5px;
}

.cmn-diamond-below-title::after {
  position: absolute;
  height: 1px;
  width: 120px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d2d2d2),
    to(rgba(210, 210, 210, 0))
  );
  background: linear-gradient(90deg, #d2d2d2 0%, rgba(210, 210, 210, 0) 100%);
  content: "";
  display: inline-block;
  left: 0;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.cmn-diamond-below-title::before {
  position: absolute;
  height: 1px;
  width: 120px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d2d2d2),
    to(rgba(210, 210, 210, 0))
  );
  background: linear-gradient(90deg, #d2d2d2 0%, rgba(210, 210, 210, 0) 100%);
  content: "";
  display: inline-block;
  right: 0;
}

.ml-different-title {
  margin-left: 8%;
}

.title-margin {
  margin-left: 8%;
}

/***** Lab Grown Diamonds section *****/
.lab-grown-diamond-section {
  padding: 4.6% 0 5.6% 0;
}

.lab-grown-diamond-section {
  background-image: url("../images/backgroundimage/section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.mb-lab-grown-diamond {
  margin-bottom: 40px;
}

/*  Diamonds In Classic Shapes */
.bg-cmn-property {
  background-repeat: no-repeat;
  background-size: cover;
}

.diamond-classic-shapes {
  background-image: url("../images/backgroundimage/classic-shapes-bg.png");
  padding: 5.3% 15px 7.5% 15px;
  background-position: center;
}

.white-header {
  text-align: center;
  color: #fff;
}

.white-header span {
  font-family: "Great Vibes";
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.05em;
  -webkit-text-stroke: 0.3px #fff;
  margin-bottom: 4px;
}

.white-header h1 {
  color: #fff !important;
  -webkit-text-stroke: 0.1px #fff !important;
}

.round-look-classic-shape-container .round-look-classic-shape-image {
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.round-look-classic-shape-container .round-look-classic-shape-image span {
  display: block;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  color: #333333;
  text-align: center;
  position: absolute;
}

.round-look-classic-shape-container .round-look-classic-shape-image img {
  border: 26px solid #fff;
  border-radius: 50%;
  background: #fff;
  min-width: 234px;
  max-width: 234px;
}

.round-look-classic-shape-image {
  transition: all 0.5s ease;
}

.round-look-classic-shape-container:hover .round-look-classic-shape-image {
  transform: translateY(-20px);
}

.pera-89 {
  width: 89%;
  margin: 0 auto;
  margin-top: 15px;
}

.nmt-9 {
  margin-top: -9%;
}

.pb-classic {
  padding-bottom: 4.6%;
}

.classic-leartmore-btn {
  margin-top: 34px;
}

/* client-serving */
.client-serving {
  background-image: url("../images/backgroundimage/why-chooseus-bg.png");
  padding: 5.259% 0;
  background-color: rgba(0, 0, 0, 0.99);
  background-attachment: fixed;
}

.serving-client-header {
  width: 73%;
  margin: 0 auto;
}

.serving-client-box {
  background: #ffffff;
  border: 1px solid #ededed;
  -webkit-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 14px 15px;
  min-height: 270px;
  transition: all 0.5s ease;
}

.serving-client-box:hover {
  background: #0e0e0f;
  box-shadow: 0px 6px 24px rgb(254 254 254 / 50%);
}

.serving-client-box:hover .cmn-peragraph,
.serving-client-box:hover h3 {
  color: #ffffff !important;
}

.serving-client-box p {
  margin-bottom: 0;
  text-align: left;
  transition: all 0.5s ease;
}

.serving-client-box h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  transition: all 0.5s ease;
}

.serving-client-box .box-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 3px;
}

.serving-client-wrapper {
  margin-top: 40px;
}

.serving-client-row2 {
  margin-top: 30px;
}

/* Lab grown and  Natural diamond  */
.labgrown-natural-diamond {
  background: #f7f7f7;
  padding: 5.26% 0;
}

.labgrown-title-container .labgrown-span {
  font-family: "Great Vibes";
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.05em;
  color: #333333;
  -webkit-text-stroke: 1px #333333;
}

.labgrown-title-container h1 {
  width: 70%;
  margin: 0 auto;
}

.labgrown-table {
  margin-top: 39px;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
}

.labgrown-table thead {
  color: #fff;
  border-radius: 5px 5px 0px 0px;
}

.labgrown-table thead tr {
  background: #005fc1;
}

.labgrown-table thead tr th:nth-of-type(1) {
  border-top-left-radius: 5px;
}

.labgrown-table thead tr th:nth-of-type(3) {
  border-top-right-radius: 5px;
}

.labgrown-table thead th {
  border: none;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.labgrown-table tbody tr {
  background: #ffffff;
}

.labgrown-table td,
.labgrown-table th {
  padding: 0.704rem;
  vertical-align: top;
  border-top: 1px solid #ededed;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
}

.f-16 {
  font-size: 16px !important;
}

/* What is 4c's Of Diamonds​ */
.diamond-4c {
  padding: 5.26% 0;
}

.c4-container {
  background-image: url("../images/backgroundimage/4c.png");
  background-size: cover !important;
  background-origin: content-box;
}

.c4-container .diamond-4c-inner {
  margin: 47.5px 30px;
  letter-spacing: 0.02em;
}

.c4-container .diamond-4c-inner h2 {
  color: #333333;
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 14px;
}

.c4-container .diamond-4c-inner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
}

.c4-container .diamond-4c-inner p:nth-of-type(2) {
  width: 97%;
}

.c4-container .diamond-4c-inner a {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  padding: 11px 13px;
  color: #005fc1;
  display: inline-block;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
}

/* footer */
.footer {
  background: #000000;
  position: relative;
}

.address-number .location a,
.address-number .number a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.address-number .location a p,
.address-number .number a p {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #787878;
  margin-bottom: 0;
}

.address-number .location a p:nth-of-type(1),
.address-number .number a p:nth-of-type(1) {
  margin-bottom: 11px;
  width: 73%;
}

.footer-links {
  margin-top: 67px;
  margin-left: 48px;
}

.footer-links h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  margin-bottom: 12px;
}

.footer-links ul {
  list-style: none;
}

.footer-links ul li a {
  text-decoration: none;
  color: #787878;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 14px;
  line-height: 30px;
}

.footer-links ul li a:hover {
  color: #ffff;
}

.mail-submit button {
  padding: 11.09px 46.1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: #fff;
  background: #005fc1;
  border-radius: 5px;
  border: none;
  margin-left: 40px;
  min-height: 48px;
}

.image-mail-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.image-mail-btn div {
  display: inline-block;
}

.image-mail-btn span {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fff;
  width: 27%;
  margin-left: 5%;
  display: inline-block;
}

.image-mail-btn input {
  min-height: 48px;
  min-width: 360px;
  color: #787878;
  outline: none;
  border: none;
  border-radius: 5px;
  background: #f7f7f7;
  padding-left: 15px;
  margin-left: 40px;
}

.image-mail-btn img {
  z-index: 555;
  position: relative;
}

.footer-row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-row2 {
  margin-top: 40px;
}

.spacer {
  height: 2px;
  margin-top: 32px;
  width: 100%;
}

.right-reserved-social {
  background: #005fc1;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.right-reserved-social p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
}

.right-reserved-social a {
  margin-left: 25px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}

.right-reserved-social i {
  transition: all 0.5s ease;
}

.right-reserved-social a:hover i {
  transform: scale(1.2);
}

/**************************
 Stock page 
 ***********************/

.stock-container {
  padding: 1px 0 2.4% 0;
  background-image: url("../images/backgroundimage/page-bg.png");
}

/* .igi-logo2 {
  max-width: 118px;
  vertical-align: middle;
} */
.product-detail-container {
  background-position: center;
}

.flow {
  border-bottom: 1px solid #ededed;
  position: relative;
  z-index: auto;
}

.flow-innner {
  margin-left: 197px;
  /* position: relative;
  z-index: 88888888; */
}

.flow-innner .breadcrumb {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 8888888888888;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
}

.flow-innner .breadcrumb li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #787878;
  text-decoration: none;
}

.flow-innner .breadcrumb li a.active-link {
  color: #005fc1;
  cursor: pointer;
}

.flow-innner .breadcrumb li i {
  color: #005fc1;
  font-size: 14px;
  line-height: 14px;
  margin: 0px 10px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

.stock-information-title span {
  font-family: "Great Vibes";
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.05em;
  -webkit-text-stroke: 1px #333333;
}

.filter-option {
  padding: 0px 15px;
  background-color: #ffffff;
}

.side-panel-fliter-titile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 4px 4px 0px 0px;
  padding: 11px 14px;
}

.side-panel-fliter-titile span {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: #005ec1;
}

.side-panel-fliter-titile span.filter {
  color: #005fc1;
  font-size: 18px;
}

.side-panel-fliter-titile i {
  margin-right: 5px;
}

.diamond-shap-titile {
  padding-top: 9px;
  padding-bottom: 7px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: inherit;
}

.diamond-shap-titile span {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
}

.diamond-shap-titile span :nth-last-child(1) {
  cursor: pointer;
  padding: 2px 6px;

  color: #005fc1;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 5px;
  display: inline-block;
}

.diamond-shap-titile:focus {
  outline: none;
}

.diamond-shap-titile i {
  color: #005fc1;
}

.diffrent-shape-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.diffrent-shape-images ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.diffrent-shape-images ul .single-image {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 7px;
}

.diffrent-shape-images ul .single-image .img-div {
  width: 54px;
  height: 54px;
  border: 2px solid #e8e8e8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.diffrent-shape-images ul .single-image .img-div:hover {
  border-color: #005fc1;
}

.diffrent-shape-images ul .single-image .img-div img {
  padding: 8px;
  max-width: 50px;
  max-height: 43px;
}

.diffrent-shape-images ul .single-image .img-div img.grey {
  display: block;
}

.diffrent-shape-images ul .single-image .img-div img.blue {
  display: none;
}

.diffrent-shape-images ul .single-image .img-cont {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #787878;
  text-align: center;
  margin-top: 7px;
}

.diffrent-shape-images ul .single-image.active .img-div {
  border: 2px solid #005fc1;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
}

.diffrent-shape-images ul .single-image.active .img-div img.grey {
  display: none;
}

.diffrent-shape-images ul .single-image.active .img-div img.blue {
  display: block;
}

.diffrent-shape-images ul .single-image.active .img-cont {
  color: #005fc1;
}

.shapes {
  max-height: 109px !important;
  overflow: auto;
}

/*  range slider */
.cmn-scroll {
  overflow: auto;
  margin-left: -4px;
  cursor: grab;
}

.cmn-scroll::-webkit-scrollbar {
  width: 1px;
  height: 5px;
  visibility: hidden;
  /* display: none; */
  cursor: grabbing;
  transition: all 1s ease-in-out;
}

.cmn-scroll2::-webkit-scrollbar {
  width: 3px !important;
  height: 5px;
  visibility: hidden;
  cursor: pointer !important;
  transition: all 1s ease-in-out;
}

/* Track */
.cmn-scroll::-webkit-scrollbar-track {
  background: rgba(196, 196, 196, 0.3);
  width: 1px;
  height: 5px;
  visibility: hidden;
  transition: all 1s ease;

  cursor: grabbing;
}

.range-slider-wrapper:hover .cmn-scroll::-webkit-scrollbar,
.range-slider-wrapper:hover .cmn-scroll2::-webkit-scrollbar,
.range-slider-wrapper:hover .cmn-scroll::-webkit-scrollbar-track,
.range-slider-wrapper:hover .cmn-scroll::-webkit-scrollbar-thumb {
  visibility: visible;
  display: block;
  cursor: grabbing;
}

/* Handle */
.cmn-scroll::-webkit-scrollbar-thumb {
  /*     background: #C4C4C4; */
  border-radius: 5px;
  background: #555555a8;
  visibility: hidden;
  cursor: grabbing;
}

.filter-option {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
}

.side-panel-filter {
  -webkit-box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.cmn-range {
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 5px;
}

.color-range {
  height: 70px;
}

.clarity-range {
  height: 67px;
  width: 550px;
}

/* Polish Symmetry Flourescence */
.filter-sub-title {
  font-weight: 700;
  display: inline-block;
  margin-bottom: 8px;
}

.cmn-cirlce-value-secetion-wrapper {
  margin-bottom: 23px;
}

.range-slider-wrapper {
  margin-bottom: 14px;
}

.range-slider-wrapper:nth-of-type(1) {
  margin-top: 20px;
}

.carat-slize-slider .rc-slider,
.price-filter .rc-slider,
.cmn-mb-filter .rc-slider {
  margin-bottom: 10px;
}

.circle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 5px;
}

.circle-value span {
  width: 54px;
  height: 54px;
  border: 2px solid #e8e8e8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-right: 15px;
  margin-bottom: 15px;
  color: #787878;
}

.circle-value span:hover {
  border-color: #005fc1;
}

/* .circle-value span:nth-of-type(4) {
  margin-right: 0;
} */

.polish-active,
.symmetry-active,
.flourescence-active,
.lab-active {
  border-color: #005fc1 !important;
  color: #005fc1 !important;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
}

.advace-search {
  /* padding: 11px 24px; */
  /* background-color: #005fc1; */

  color: #005fc1;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  /* border-radius: 5px; */
  display: inline-block;
  /* -webkit-box-shadow: 0px 6px 14px rgba(0, 95, 193, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 95, 193, 0.25); */
  margin-bottom: 15px;
}

/* 
.advace-search:hover {
  color: #fff;
  text-decoration: none;
} */

.advace-search i {
  margin-left: 8px;
  color: grey;
}

.large-view-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.large-view-wrapper .report-id {
  display: block !important;
  margin-left: 0;
  margin-bottom: 10px;
}

.large-view-wrapper .price {
  text-align: left;
}

.large-view-wrapper .cart-like-detail-btn {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*  -ms-flex-flow: column;
  flex-flow: column; */
  margin-left: auto;
}

/* .details-btn {
  min-width: 48px;
  background-color: #333333 !important;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
} */

.large-view-btn-alignment {
  margin-left: auto;
}

.large-view-btn-alignment a:nth-of-type(2) {
  margin: 0 10px;
  margin-bottom: 15px;
}

.nav-pills .nav-link {
  color: #787878;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #005fc1;
  background-color: transparent;
}

.tabs-container a.active::after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 2px;
  background-color: #005fc1;
  bottom: -1px;
  left: 0px;
}

.tabs-and-btn {
  border-bottom: 1px solid #e5e5e5;
}

.tabs-and-btn .nav-link {
  font-weight: 700;
}

.tabs-and-btn button {
  background-color: #333333;
  color: #fff;
  padding: 12px 36px;
  border: none;
  outline: none;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.goto-compare {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 48px;
  width: 48px;
  border-radius: 5px;
  /* padding: 11px 13px; */
  -webkit-box-shadow: 0px 6px 14px rgb(0 0 0 / 25%);
  box-shadow: 0px 6px 14px rgb(0 0 0 / 25%);
  justify-content: center;
  align-items: center;
  background: #333333;
  position: fixed;
  right: 10%;
  bottom: 10px;
  z-index: 999999999;
  animation: circleblink 1s infinite alternate;
}

@keyframes circleblink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.09);
  }
}

.goto-compare img {
  max-width: 22px;
}

.change-view-btn button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  outline: none;
  border: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #ededed;
}

.iconselected {
  border-color: #005fc1 !important;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 15%);
}

.reset-btn {
  margin-top: -18px;
}

.reset-btn span {
  margin-left: 12px;
}

.container-2 {
  max-width: 1410px;
  margin-top: 30px;
}

.view-shortby {
  /* margin-top: 14px; */
  margin-bottom: 30px;
}

.view-shortby .row:nth-last-of-type(1) {
  margin-top: 30px;
}

.view-shortby .row {
  margin-bottom: 30px;
}

.sort-row {
  align-items: center;
}

.change-view-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 3px;
}

/* .change-view-btn-wrapper span {
  margin-right: 14px;
} */

.change-view-btn-wrapper button {
  margin-right: 14px;
}

.sortby {
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #787878;
}

.sortby label {
  padding-left: 20px;
  color: #333333;
  margin-bottom: 0;
}

.total {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #787878;
  padding-right: 20px;
  /* border-right: 1px solid #e5e5e5; */
}

.Create .total a {
  text-decoration: underline;
  font-weight: 700;
  color: inherit;
}

.stock-section-wrapper {
  background: #f7f7f7;
  margin-top: -30px;
}

.total span {
  font-weight: 700;
  display: inline-block;
}

.sort-by-select-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-by-select-wrapper label {
  margin-right: 16px;
}

.sort-by-select-innner {
  position: relative;
}

.sort-by-select-innner select {
  -webkit-appearance: none;
}

.sort-by-select-innner select {
  min-width: 232px;
  width: 100%;
  color: #787878;
  border: none;
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 11px 15px;
  border-radius: 5px;
}

.arrow-select {
  border: solid #787878;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 39%;
  right: 10px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.sort-by-select-innner select:focus {
  outline: none;
}

/* <!-- diamond box --> */
.cmn-diamond-box-inner-wrapper {
  background: #ffffff;
  border: 1px solid #ededed;
  /* box-sizing: border-box; */
  -webkit-box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 9px 16px 9px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

.image-overlay {
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
}

/* .image-overlay div {
  margin: 0px -24px;
} */

.image-overlay:hover .over-lay-container {
  opacity: 1;
}

.produt-singel-box-image {
  height: 223.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.produt-singel-box-image {
  text-align: center;
}

.produt-singel-box-image img {
  border-radius: 6px;
  max-width: 288px;
  max-height: 223.5px;
  transition: all 0.5s ease;
}

.produt-singel-box-image:hover img {
  /* box-shadow: 0 0 7px 8px #00000052; */
  /* transform: translateY(-10px); */
  transform: scale(1.1);
}

.over-lay-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.report-id {
  margin-left: 2px;
  margin-bottom: 5px;
}

.report-id span:nth-of-type(1) {
  color: #333333;
  font-weight: 700;
}

.report-id span:nth-of-type(2) {
  color: #787878;
  margin-left: 4px;
}

.shap-rating span:nth-of-type(1) {
  color: #000000;
  font-size: 18px;
}

.shap-rating span:nth-of-type(2) {
  color: #787878;
}

.carat-size span:nth-of-type(1),
.igi-logo-table span:nth-of-type(1) {
  color: #333333;
}

.carat-size span:nth-of-type(2),
.igi-logo-table span:nth-of-type(2) {
  color: #787878;
}

.cmn-child-div-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 4px;
  /* flex-flow: column; */
}

.price {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  /* text-align: center; */
  padding-top: 12px;
  border-top: 1px solid #ededed;
  color: #005fc1;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}

.cart-like-detail-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
}

.cart-like-detail-btn {
  min-width: 235px;
  max-width: 322px;
}

.cart-like-detail-btn a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 48px;
  width: 48px;
  border-radius: 5px;
  /*  padding: 11px 13px; */
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.cart-like-detail-btn a img {
  max-width: 22px;
}

.cart-like-detail-btn a:nth-of-type(1) {
  background: #005fc1;
}

.cart-like-detail-btn a:nth-of-type(2),
.cart-like-detail-btn a:nth-of-type(3),
.cart-like-detail-btn a:nth-of-type(4) {
  background: #333333;
}

/* .cart-like-detail-btn a:nth-of-type(4) {
  background: #333333;
  height: 48px;
  width: 48px;
  border-radius: 5px;
  padding: 11px 13px;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
} */

/* .cart-like-detail-btn a:nth-of-type(4) span {
  margin-left: 6px;
} */

#full-stars-example-two .rating-group,
#full-stars-example-one .rating-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

#full-stars-example-two .rating__icon,
#full-stars-example-one .rating__icon {
  pointer-events: none;
}

#full-stars-example-two .rating__input,
#full-stars-example-one .rating__input {
  position: absolute !important;
  left: -9999px !important;
}

#full-stars-example-two .rating__input--none,
#full-stars-example-one .rating__input--none {
  display: none;
}

#full-stars-example-two .rating__label,
#full-stars-example-one .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 0.8rem;
}

#full-stars-example-two .rating__icon--star,
#full-stars-example-one .rating__icon--star {
  color: #005fc1;
}

#full-stars-example-two
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star,
#full-stars-example-one
  .rating__input:checked
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star,
#full-stars-example-one .rating-group:hover .rating__label .rating__icon--star {
  color: #005fc1;
}

#full-stars-example-two
  .rating__input:hover
  ~ .rating__label
  .rating__icon--star,
#full-stars-example-one
  .rating__input:hover
  ~ .rating__label
  .rating__icon--star {
  color: #ddd;
}

.load-more {
  text-align: center;
  margin-top: 10px;
}

.load-more i {
  margin-right: 10px;
}

.load-more button {
  padding: 14px 20px;
  background: #005fc1;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  color: #ffff;
}

.load-more button:focus {
  outline: none;
}

/* accordion */
/* accordion */
.accordion {
  max-width: 770px;
  margin: 0 auto;
}

.accordion-wrapper {
  padding: 5.3% 0;
  background-image: url("../images/backgroundimage/accodion-bg.png");
}

.acc-header-common {
  background: #fff;
  margin-bottom: 14px;
}

.acc-header-common span {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.acc-header-common button.btn.btn-link {
  color: #333333;
  list-style: none;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
  border: none !important;
  outline: none !important;
}

.color-active,
.color-active i {
  color: rgba(0, 95, 193, 1) !important;
}

.acc-header-common button.btn.btn-link i {
  color: #787878;
}

.card {
  margin-bottom: 15px;
  border: none;
}

.card-body {
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btn.focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card-header {
  border: 1px solid #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  padding: 5.5px 16px;
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  font-weight: bold;
}

.show .card-header {
  color: red;
}

.list-style {
  margin-right: 20px;
}

.more {
  padding: 18px 34px 17px 34px;
  background-color: #ddeafd;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #121c55;
  display: inline-block;
  margin-bottom: 13px;
  text-decoration: none !important;
  border-radius: 5px;
}

.more img {
  margin-left: 12px;
  border-radius: 4px;
}

.accordion > .card > .card-header {
  border-radius: 5px;
  margin-bottom: 0px;
}

/* labgrown diamond page */
.lab-grown-dimaond-page {
  padding: 0px 0px 5.3% 0;

  /* border-top: 1px solid #ededed; */
}

.container3 {
  max-width: 1000px;
}

/*******************

Product details page start

*****************/
.slider-nav .single-image {
  max-width: 92px;
}

.single-image-slider {
  max-width: 124px;
  position: relative;
  padding: 0 5px;
}

.video-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video-overlay img {
  width: 21px;
  height: 26px;
}

.previewer-carousel {
  margin-top: 15px;
  max-width: 570px;
  max-height: 570px;
  text-align: center;
  /* background: #f7f7f7; */
  border-radius: 5px;
}

.previewer-carousel canvas {
  border-radius: 5px;
}

.shown-image {
  max-width: 554px;
}

.previewer-carousel .slick-prev:before,
.previewer-carousel .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #005fc1 !important;
  font-size: 31px;
  display: none;
}

.previewer-carousel .slick-next,
.previewer-carousel .slick-next:hover {
  background-image: url("../images/backgroundimage/product-right.png");
  background-repeat: no-repeat;
  background-position: center;
}

.previewer-carousel .slick-prev,
.previewer-carousel .slick-prev:hover {
  background-image: url("../images/backgroundimage/product-left.png");
  background-repeat: no-repeat;
  background-position: center;
}

.widthcontroller {
  max-width: 87%;
  margin-left: auto;
  margin-right: 8%;
  margin-top: 15px;
}

.shown-image {
  max-width: 587px;
}

.table-titile-with-image {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 13px;
}

.table-titile-with-image img {
  margin-right: 10px;
  max-width: 38px;
}

.product-page-table {
  letter-spacing: 0.02em;
  max-width: 470px;
  background: #f7f7f7;
  padding: 13px 20px 5px 20px;
  border-radius: 5px;
}

.product-page-table table {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.product-page-table table tr td {
  padding-bottom: 5px;
  color: #787878;
}

.product-page-table table tr td:nth-of-type(1) {
  letter-spacing: 0.02px;
}

.product-page-table table tr td:nth-of-type(2) {
  /* text-align: right; */
  color: #787878;
}

.product-page-table table tr th:nth-of-type(1) {
  width: 47%;
}

.product-page-table table tr th:nth-of-type(2) {
  width: 40.33%;
}

/* .product-page-table table tr th:nth-of-type(3){
  width: 40%;
} */

.white-bg-container {
  background: #ffffff;
  border-radius: 5px;
}

.box-shadow {
  -webkit-box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);
  box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);
}

.carat-size-container {
  padding: 4.5% 75px 10% 75px !important;
}

.table-parice {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #005fc1;
  margin: 11px 0px 8px 0px;
  display: inline-block;
}

.dimond-description {
  margin-top: 36px;
}

.dimond-description h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #333333;
  margin-bottom: 4px;
}

.dimond-description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #787878;
  margin-bottom: 11px;
}

.product-page-report-id {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.product-page-report-id span:nth-of-type(1) {
  color: #333333;
  font-weight: 700;
}

.product-page-report-id span:nth-of-type(2) {
  color: #787878;
  font-weight: 400;
  margin-left: 4px;
}

.produt-page-like-wishlistbtn {
  margin: 30px 0px;
}

.produt-page-like-wishlistbtn a {
  min-width: 160px;
  min-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.produt-page-like-wishlistbtn a img {
  margin-right: 12px;
}

.produt-page-like-wishlistbtn a:nth-of-type(1) {
  background: #005fc1;
  color: #fff;
  margin-right: 30px;
}

.produt-page-like-wishlistbtn a:nth-of-type(1):hover {
  background: #005fc1;
  color: #fff;
}

.produt-page-like-wishlistbtn a:nth-of-type(2) {
  background: #333333;
  color: #fff;
}

.produt-page-like-wishlistbtn a:nth-of-type(2):hover {
  background: #333333;
  color: #fff;
}

.social-link-drop-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 14px 0px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  max-width: 470px;
}

.social-link-drop-list a i {
  color: #787878;
  font-size: 18px;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.social-link-drop-list a:nth-of-type(1) {
  margin-left: 5px;
}

.drop-hint a {
  color: #787878;
}

.social-link-drop-list a i:hover,
.drop-hint a:hover,
.drop-hint a:hover i {
  color: #005fc1;
}

.shipping-details {
  border: 1px solid #ededed;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 15px;
  max-width: 470px;
  margin-top: 30px;
}

.shipping-details p {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin-left: 13px;
}

.shipping-details p:nth-of-type(1) {
  font-weight: 700;
  color: #333333;
}

.shipping-details p:nth-of-type(2) {
  font-weight: 400;
  color: #787878;
}

.diamond-detils-container .container {
  padding: 3% 30px 4.6% 30px !important;
  background-color: #fff;
}

.view-report {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  border: none;
  background: transparent;
  color: #005fc1;
  text-decoration: underline;
}

.igi-logo2 {
  margin-bottom: 9px;
  max-width: 68px;
  vertical-align: bottom;
  display: inline-block;
}

.diamond-details-sub-table .diamond-details-sub-table-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #005fc1;
  margin-bottom: 4px;
  display: inline-block;
}

.diamond-details-sub-table tr td {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.02em;
}

.diamond-details-sub-table tr td:nth-of-type(1) {
  color: #333333;
  font-weight: 700;
}

.diamond-details-sub-table tr td:nth-of-type(2) {
  color: #787878;
  padding-left: 15px;
}

.big-container {
  background: #f7f7f7;
  padding: 4.5% 30px;
}

.big-container h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #005fc1;
  text-align: center;
  margin-bottom: 30px;
}

.big-container h2 span {
  color: #333333;
}

.big-container-white-bg {
  background: #fff;
}

.carat-size-single-image {
  position: relative;
}

.carat-size-single-image .diamond-size {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #787878;
}

.carat-size-single-image .diamond-size span {
  display: block;
}

.carat-size-single-image::after {
  position: absolute;
  content: "";
  height: 24px;
  width: 2px;
  background-color: #c4c4c4;
  left: 50%;
}

.image-with-tooltip {
  position: relative;
}

.image-with-tooltip-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.diamond-size {
  text-align: center;
  margin-top: 12px;
}

.linear-line {
  height: 2px;
  background-color: #c4c4c4;
  margin-top: 24px;
  max-width: 973px;
  margin-left: auto;
  margin-right: 49px;
}

.dimond-below-box-show-size {
  display: inline-block;
  background-color: rgba(0, 95, 193, 0.07);
  padding: 5.5px 10px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  margin-top: 10px;
  min-width: 150px;
  bottom: -103px;
  left: 13px;
}

.dimond-below-box-show-size span {
  display: block;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #005fc1;
}

.dimond-below-box-show-size span:nth-of-type(2) {
  font-weight: 700;
  margin-top: 5px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid rgba(0, 95, 193, 0.07);
  display: inline-block;
  top: -12px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.button-with-tooltip {
  border: 1px solid #ededed;
  border-radius: 5px;
  display: inline-block;
}

.single-button-with-tooltip {
  width: 100%;
  border-bottom: 1px solid #ededed;
}

.single-button-with-tooltip button {
  width: 100%;
  padding: 10px 62px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #787878;
  cursor: default;
}

.single-button-with-tooltip button:hover,
.btn:hover {
  color: #787878;
}

.single-button-with-tooltip .button-actives {
  background: #f7f7f7;
  color: #005fc1 !important;
}

.pop-content {
  color: #005fc1;
}

.pop-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #787878;
  margin-bottom: 0 !important;
}

.popover-body {
  background: rgba(0, 95, 193, 0.07);
  border-radius: 5px;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #787878;
  font-family: "Roboto Slab", serif;
}

.pop-content {
  color: #005fc1;
  position: absolute;
  left: 107%;
  width: 100%;
  top: 0;
  /* height: 100%; */
  min-width: 373px;
  background: rgba(0, 95, 193, 0.07);
  border-radius: 6px;
  display: none;
  padding: 12px 14px;
}

.pop-show {
  display: block;
}

.pop-show:before {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid rgba(0, 95, 193, 0.07);
  position: absolute;
  top: 13px;
  left: -12px;
}

.popover {
  border: none;
  min-width: 380px;
}

.arrow::after {
  border-right-color: #d8e5f2 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.popover-header {
  color: #005fc1 !important;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding-left: 0;
}

/* Procut detailscolor start  */
.color-image-details-single {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.color-image-details-wrapper {
  display: flex;
  justify-content: center;
}

.color-image-details-single {
  padding: 0px 13px;
}

.color-image-details-wrapper-row {
  display: flex;
}

.color-image-details-wrapper-bottom-titile {
  text-align: center;
  border-top: 2px solid #ededed;
  max-width: 230px;
  margin: 0 auto;
  padding-top: 9px;
  margin-top: 9px;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
}

.color-image-details-wrapper-bottom-titile2 {
  max-width: 340px;
}

.hover-content-on-image {
  background: rgba(0, 95, 193, 0.07);
  min-width: 350px;
  padding: 13px;
  border-radius: 5px;
  position: relative;
  margin-top: 12px;
  display: none;
}

.hover-content-on-image.show {
  display: block;
}

.hover-content-on-image:before {
  content: "";
  border-bottom: 15px solid rgba(0, 95, 193, 0.07);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%);
}

.hover-content-on-image h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #005fc1;
  margin-bottom: 1px;
}

.hover-content-on-image h3 span {
  font-weight: 700;
  margin-left: 5px;
}

.hover-content-on-image p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #787878;
  margin-bottom: 0;
}

/* product detailscolor end */

.last-container-white {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.slider {
  width: calc(100% - 30px);
  margin: 20px 0;
}

.ui-widget-content {
  height: 4px;
  background-color: #b7b7b7;
  border: none;
  position: relative;
}

.ui-widget-header {
  background-color: #005fc1;
  position: absolute;
  height: 100%;
  outline: none;
}

.ui-slider-handle {
  top: -6px;
  height: 16px;
  width: 16px;
  background-color: #005fc1;
  border-radius: 10px;
  border-color: #005fc1;
  position: absolute;
  outline: none;
  z-index: 5;
}

.ui-slider-handle:after {
  position: absolute;
  display: block;
  content: "";
  width: 28px;
  height: 28px;
  background: #005fc1;
  border-radius: 50%;
  z-index: 0;
  top: -6px;
  left: -6px;
  opacity: 0.5;
}

.slider-value {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.slider-value .slidervalue-box {
  width: 20%;
}

.slidervalue-box-right-text {
  text-align: right;
}

.slider-value .slidervalue-box label {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  margin-bottom: 0px;
}

.slidervalue-box {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
}

.slider-value .slidervalue-box input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
}

.range-input {
  border: 1px solid #dcdcdc !important;
  border-radius: 5px;
  text-align: center;
  background: #3333332b;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: "Always Show Up/Down Arrows";
  opacity: 1;
}

.color-cont ul {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  list-style: none;
}

.color-cont ul li {
  position: relative;
  display: inline-block;
  width: auto;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  margin: 0 8px;
}

.color-cont ul li:first-child {
  margin-left: 0;
}

.color-cont ul li:last-child {
  margin-right: 0;
}

.clarity-cont ul li {
  margin: 0 2px;
}

.moretext {
  display: none;
}

.range-slider-wrapper {
  min-height: 75px;
}

.rc-slider {
  width: 95% !important;
  display: block;
  margin: 0 auto;
}

.rc-slider-handle {
  border: solid 2px #005fc1 !important;
  background-color: #005fc1 !important;
}

.rc-slider-dot-active {
  border-color: #005fc1;
}

.rc-slider-track {
  background-color: #005ec1 !important;
}

.rc-slider-dot {
  border: 2px solid #005fc1 !important;
  background-color: #005fc1 !important;
}

.rc-slider-handle-dragging {
  /* border-color: #005ec1 !important; */
  -webkit-box-shadow: 0 0 0 5px rgba(0, 93, 193, 0.5) !important;
  box-shadow: 0 0 0 5px rgba(0, 93, 193, 0.5) !important;
}

/*******************

Product details page end

*****************/
/*******************
Product details page end
*****************/
/*******************
compare  page start
*****************/
.container3 {
  max-width: 1440px;
}

.compare-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 150px;
  overflow: auto;
  height: 88vh;
  cursor: -webkit-grab;
  cursor: grab;
}

.compare-fixedpart,
.compare-scrollpart {
  padding: 0px 15px;
}

.compare-fixedpart {
  position: sticky;
  left: 0;
  z-index: 55;
  padding-left: 0;
}

.compare-scroll::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}

/* Track */
.compare-scroll::-webkit-scrollbar-track {
  background: rgba(196, 196, 196, 0.3);
  width: 4px;
  height: 4px;
}

/* Handle */
.compare-scroll::-webkit-scrollbar-thumb {
  /*     background: #C4C4C4; */
  border-radius: 5px;
  background: #555;
}

@media (min-width: 768px) {
  .my-order-main-wrapper,
  .address--main-wrapper {
    margin: 8px -15px 27px -15px !important;
  }
}

@media (min-width: 360px) and (max-width: 400px) {
  .compare-fixedpart,
  .compare-scrollpart {
    padding: 0px 15px;
  }
}

.compare-cmn-part ul {
  min-width: 323px;
  max-width: 323px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.compare-cmn-part ul li {
  min-height: 48px;
  max-height: 48px;
  font-size: 14px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid #e5e5e5;
  padding: 0px 15px;
  background: #ffffff;
}

.compare-cmn-part ul .cmn-not-shown-item {
  pointer-events: none;
}

.compare-cmn-part ul .id-close-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.compare-cmn-part ul .id-close-icon span:nth-of-type(2) {
  cursor: pointer;
}

.compare-cmn-part ul .image-li {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  max-height: 266px;
}

.compare-cmn-part ul .image-li img {
  max-height: 152px;
}

.compare-cmn-part ul .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 47px;
  color: #005fc1;
  background: #f2f2f2;
}

.compare-lab-icon img {
  max-width: 50px;
}

.none-yellow-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.none-yellow-btn button {
  background: #232323;
  border-radius: 5px;
  padding: 0px 13px;
  color: #fff;
  line-height: 26px;
  height: 26px;
  margin: auto 0;
  outline: none;
  border: none;
}

.compare-fixedpart-inner li {
  color: #333333;
}

.compare-scroll-inner li {
  color: #787878;
}

.fixed-ul {
  border: none;
  /* visibility: hidden; */
  max-height: 309px;
}

.compare-scrollpart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* max-width: 1029px;
  min-width: 150px;
  overflow: auto; */
}

.compare-scrollpart .compare-scroll-inner:not(:nth-last-of-type(1)) {
  margin-right: 30px;
}

/*******************
compare  page end
*****************/
/*******************
Thank you start
*****************/
.container4 {
  max-width: 400px;
}

.thankyou-wrapper {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.thankyou-wrapper {
  letter-spacing: 0.02em;
}

.thankyou-wrapper h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: #333333;
  margin: 15px 0px 20px 0px;
}

.thankyou-wrapper p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #787878;
  margin: 23px 0px 45px 0px;
}

.thankyou-wrapper p span {
  font-weight: 700;
  display: block;
}

.order-number {
  padding: 10px 28px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #005fc1;
  background: #f2f2f2;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.back-to-home-btn {
  padding: 11px 22px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: #fff;
  background: #005fc1;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 95, 193, 0.3);
  box-shadow: 0px 6px 14px rgba(0, 95, 193, 0.3);
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 61px;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.back-to-home-btn:hover {
  background-color: transparent;
  border: 1px solid #005fc1;
  color: #005fc1;
}

/*******************
Thank you end
*****************/
/*******************
Regdister start
*****************/
.cmn-shadow-bg {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.register-container {
  max-width: 830px;
}

.cmn-title-form {
  text-align: center;
  padding: 31px 0px 15px 0px;
}

.cmn-title-form span {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.02em;
  color: #005fc1;
}

.register-btn {
  text-align: center;
  padding: 20px 0px 30px 0px;
}

.register-btn button {
  padding: 15px 42px;
  background: #333333;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  border: none;
}

/*******************
Register end
*****************/
/*******************
sign-in  start
*****************/
.container5 {
  max-width: 600px;
}

.signin-wrappper {
  margin: 0 auto;
}

.sign-in-input .input-box {
  width: 70% !important;
}

.input-box input:focus,
.input-box textarea:focus,
.input-box select:focus {
  box-shadow: 0px 0px 2px black;
}

.input-box input[type="number"]::-webkit-inner-spin-button,
.input-box input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input-box textarea:focus {
  outline: none;
  border: none;
}

.sign-in-input {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.forgote-password {
  text-align: right;
  width: 83%;
}

.forgote-password a {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  display: inline-block;
  color: #787878;
  text-decoration: underline;
}

.signin-btn {
  padding: 30px 0px;
}

/*******************
sign-in end
*****************/
/*******************
myaccount start
*****************/
.myaccount-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.myaccount-tabs .nav {
  min-width: 270px;
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.myaccount-tabs .nav-link {
  display: block;
  padding: 0.75em 1rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #787878;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-bottom: 1px solid #ededed;
}

.myaccount-tabs .nav-link.active {
  position: relative;
  text-decoration: none;
  background-color: #005fc1 !important;
  color: #fff !important;
}

.myaccount-tabs a.active::after {
  position: relative;
}

.myaccount-tabs .tab-description {
  max-width: 870px;
  width: 100%;
  margin-left: 30px;
  padding: 8px 15px 27px 15px;
}

.tab-description .tabs-titile {
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.02em;
  color: #005fc1;
  margin-bottom: 17px;
}

.tab-description .save-btn {
  margin-top: 48px;
}

.tab-description .save-btn button {
  padding: 12px 53px;
  background: #333333;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  border: none;
}

/* address*/
.addres-titile {
  margin-bottom: 18px;
  display: inline-block;
}

.single-address-contianer {
  width: 50%;
}

.border-bottom {
  border-bottom: 1px solid #ededed;
}

.address-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.address-inner .border-rights:nth-child(even) {
  border-right: none !important;
}

.adddres-left-margin {
  padding-left: 4%;
}

.name-with-edit-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 15px;
}

.address-details ul {
  padding: 0 15px;
}

.name-with-edit-delete span:nth-of-type(1) {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
}

.name-with-edit-delete span:nth-of-type(2) {
  margin-right: 8%;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
}

.name-with-edit-delete span:nth-of-type(2) .fa-pen {
  color: #005fc1;
  margin-right: 10px;
}

.name-with-edit-delete span:nth-of-type(2) .fa-save {
  color: #005fc1;
  margin-right: 10px;
}

.name-with-edit-delete span:nth-of-type(2) .fa-trash-alt,
.name-with-edit-delete span:nth-of-type(2) .fa-times {
  color: #787878;
}

.border-rights {
  border-right: 1px solid #ededed;
}

.address-details {
  margin-top: 18px;
}

.address-details ul {
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #787878;
  margin-top: 5px;
}

.address-details ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin-bottom: 5px;
}

.address-details ul li:nth-last-child(1) {
  margin-bottom: 34px;
}

.address-details ul li span {
  display: inline-block;
}

.address-details ul li span:nth-of-type(1) {
  font-weight: 500;
  color: #333333;
  min-width: 107px;
}

.address-box2 span:nth-of-type(1) {
  min-width: 123px;
}

.address-details ul li span:nth-of-type(2) {
  margin-left: 0px;
  display: block;
  width: 100%;
}

.address-details ul li:nth-of-type(1),
.address-details ul li:nth-of-type(1) input,
.address-details ul li:nth-of-type(4),
.address-details ul li:nth-of-type(4) input {
  text-transform: capitalize;
}

.modal {
  background: #0000007a;
}

.modal-footer button:nth-of-type(1) {
  background: #333333;
}

.modal-footer button:nth-of-type(2) {
  background: #005fc1;
}

.modal-header {
  color: #005fc1;
  font-weight: 700 !important;
}

.address-details ul li .address-margin {
  margin-left: 0px !important;
}

.billing-input-px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.address-spacer {
  padding-top: 3%;
}

.address-box2 input,
.address-box2 select {
  border: 1px solid #7878785c;
  background: transparent !important;
  border-radius: 3px;
  padding-left: 5px;
  outline: none;
  color: #787878;
  min-height: 26px;
  width: 100%;
  margin-left: 2px;
}

/* my oreder */
.single-order {
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 6px;
  margin-bottom: 30px;
}

.single-order span {
  display: inline-block;
}

.order-image {
  position: relative;
  height: 193px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-image img {
  max-height: 193px;
}

.order-image .overlay-date {
  position: absolute;
  right: 0;
  top: 9px;
}

.order-image .overlay-date span {
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  color: #787878;
}

.order-image-descrption span {
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}

.order-image-descrption span.order-carat {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 10px;
  margin-top: 10px;
}

.order-image-descrption span.order-round-sort {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 10px;
}

.order-image-descrption span.order-price {
  font-size: 18px;
  font-weight: 500;
  color: #005fc1;
  display: block;
  margin-bottom: 10px;
}

.order-image-descrption .cmn-dots {
  position: relative;
}

.order-image-descrption .cmn-dots::before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 5px;
}

.order-image-descrption .pending::before {
  background-color: #ff901a;
}

.order-image-descrption .delivered::before {
  background-color: #90cc00;
}

.order-image-descrption .cancle::before {
  background-color: #dd3434;
}

.order-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-status span {
  color: #787878;
}

.order-status a {
  text-decoration: none;
  color: #005fc1;
  text-decoration: underline;
}

.order-status a img {
  margin-left: 10px;
}

/*******************
myaccountend
*****************/
/* G-css start */
.mask {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999999999;
}

.mask .img-box {
  width: 100%;
  max-width: 650px;
  padding: 10px;
  background: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mask .img-box img {
  width: 100%;
}

.mask .img-box .close {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: -35px;
  right: -35px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}

.mask .img-box .close:hover {
  background: white;
}

.is-visible {
  display: block !important;
}

.fadein {
  -webkit-animation: fadein 400ms ease-in-out;
  animation: fadein 400ms ease-in-out;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeout {
  -webkit-animation: fadeout 400ms ease-in-out;
  animation: fadeout 400ms ease-in-out;
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.shopping-value {
  width: 37%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.goto-cart-image img {
  max-width: 152px;
}

.large-view-wrapper .shopping-value .price {
  text-align: center;
}

.shopping-value .price {
  padding: 0;
  margin: 0;
  border: 0;
}

.large-view-size-table-shapw-wrapper {
  position: relative;
  display: block;
}

.large-view-size-table-shapw-wrapper:after {
  position: absolute;
  display: block;
  content: "";
  height: 100%;
  border-left: 1px solid #ededed;
  right: -30px;
  top: 0;
  border-radius: 5px;
}

.shopping-value a {
  position: absolute;
  display: block;
  width: auto;
  top: 0;
  right: 0;
}

.carat-link a {
  font-size: 14px;
  line-height: 20px;
  color: #005fc1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.cart-summary-box {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
}

.cart-summary-box .cart-title {
  background: #f2f2f2;
  border: 1px solid #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 15px;
}

.cart-summary-box .cart-title h5 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #005fc1;
  margin: 0;
  font-weight: 700;
}

.cart-summary-box .cart-box ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.cart-summary-box .cart-box ul li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #ededed;
  height: 40px;
  padding: 0 15px;
}

.cart-summary-box .cart-box ul li span:first-child {
  font-size: 14px;
  line-height: 47px;
  letter-spacing: 0.02em;
  color: #787878;
}

.cart-summary-box .cart-box ul li span:last-child {
  font-size: 14px;
  line-height: 46px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #333333;
}

.cart-summary-box .cart-box ul li.carttotal {
  margin-bottom: 20px;
  padding-top: 10px;
}

.cart-summary-box .cart-box ul li.carttotal span:first-child {
  font-size: 22px;
  line-height: 46px;
  letter-spacing: 0.02em;
  color: #787878;
  font-weight: 700;
}

.cart-summary-box .cart-box ul li.carttotal span:last-child {
  font-size: 22px;
  line-height: 24px;
  text-align: right;
  color: #005fc1;
  font-weight: 700;
}

.cart-summary-box .cart-button {
  padding: 0 15px;
}

.cart-summary-box .cart-button .cart-btn {
  position: relative;
  display: block;
  width: 100%;
  background: #005fc1;
  -webkit-box-shadow: 0px 6px 14px rgba(70, 67, 67, 0.25);
  box-shadow: 0px 6px 14px rgba(70, 67, 67, 0.25);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  height: 48px;
  padding: 11px 0px;
  text-decoration: none;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  margin-bottom: 18px;
}

.cart-summary-box .cart-button .cart-btn:hover {
  text-decoration: none;
  color: #fff;
}

.cart-summary-box form .checkout-input-box {
  background: #ffffff;
  border: 1px solid #ededed;
  margin-bottom: 40px;
}

.billing-input-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 20px;
}

.billing-input-box .input-box {
  width: 48%;
}

.billing-input-box .input-box.sign-up-input {
  width: 100%;
}

.billing-input-box .input-box label {
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 5px;
  font-weight: 500;
  margin: 0;
}

.billing-input-box .input-box input,
.billing-input-box .input-box select {
  position: relative;
  display: block;
  width: 100%;
  background: #f7f7f7;
  border-radius: 5px;
  border: 1px solid transparent;
  height: 48px;
  font-size: 14px;
  line-height: 26px;
  color: #787878;
  padding: 10px 20px;
  outline: none;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.billing-input-box .input-box input.error-border,
.billing-input-box .input-box select.error-border {
  border-color: red;
}

.error-border {
  background-image: url("../images/validationIcon/not-valid.png") !important;
  background-position: 97% center !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
  z-index: 4;
}

.isValid {
  background-image: url("../images/validationIcon/valid.png") !important;
  background-position: 97% center !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
}

.isPassword {
  background-image: url("../images/validationIcon/password.png") !important;
  background-position: 97% center !important;
  background-repeat: no-repeat !important;
  background-size: 15px !important;
}

.password-eye {
  float: right;
  margin-right: 9px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.cart-basket {
  font-size: 0.6rem;
  position: absolute;
  width: 21px;
  height: 21px;
  top: -12px;
  right: -17px;
  color: #ffffff;
  background-color: #005fc1;
  border-radius: 50%;
}

.red-star {
  color: red;
}

.billing-input-box .input-box2 {
  width: 100%;
}

.billing-input-box .radio-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer !important;
}

.radio-input label {
  cursor: pointer !important;
}

.billing-input-box .radio-input input {
  display: inline-block;
  width: auto;
  height: auto;
}

.billing-input-box2 {
  padding: 0;
  justify-content: start !important;
}

.billing-input-box2 .radio-input {
  width: fit-content;
  margin-right: 16px;
}

.billing-input-box2 input {
  margin-right: 9px;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.hide {
  display: none;
}

.cart-summary-box2 {
  border: none;
  background: transparent;
  padding: 0;
}

.checkout-cart-summary {
  max-height: 400px;
  overflow: auto;
}

.shippping-address-choice {
  margin-top: 15px;
}

.pay-now-btn {
  background-color: rgba(0, 95, 193, 1) !important;
}

.pay-now-btn:hover {
  background: transparent !important;
  color: #005ec1 !important;
  border: 1px solid #005ec1 !important;
}

.shipping-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
  padding: 10px 20px;
}

.shipping-box .shipping-icon {
  width: 15%;
}

.shipping-box .shipping-cont {
  width: 85%;
}

.shipping-box .shipping-cont h5 {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #333333;
  margin: 0;
  font-weight: 700;
}

.shipping-box .shipping-cont p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  margin: 0;
}

.shape-boxes {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 15px;
}

.shape-boxes .shape-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 77%;
}

.shape-boxes .shape-box .shape-image {
  width: 25%;
}

.shape-boxes .shape-box .shape-image img {
  border-radius: 5px;
}

.shape-boxes .shape-box .shape-cont {
  width: 75%;
  margin-left: 3%;
}

.shape-boxes .shape-box .shape-cont h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #333333;
  margin: 0;
}

.shape-boxes .shape-nmbr {
  width: 23%;
  text-align: right;
}

.shape-boxes .shape-nmbr h5 {
  font-size: 14px;
  line-height: 24px;
  color: #787878;
  margin: 0;
}

.shape-boxes .shape-nmbr p {
  font-size: 14px;
  line-height: 24px;
  color: #787878;
  margin: 0;
}

.payment-input {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 20px;
  padding-top: 15px;
}

.payment-input .radio-payemt {
  margin-right: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-input .radio-payemt:last-child {
  margin-right: 0;
}

.payment-input .radio-payemt label {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  font-weight: 700;
  margin: 0;
}

.payment-input .radio-payemt input {
  margin-right: 15px;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.billing-address {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 40px 0;
}

.billing-address .billing-address-cont h5 {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #787878;
  font-weight: 700;
  margin: 0;
}

.billing-address .billing-address-cont input {
  margin-right: 15px;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  float: left;
  margin-top: 4px;
}

.billing-address .billing-address-cont label {
  font-size: 14px;
  line-height: 26px;
  color: #787878;
  margin: 0;
}

.payment-card {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 13px 30px;
}

.payment-card ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.payment-card ul li {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 0 10px;
}

/*  */
.interesting-img {
  position: relative;
  text-align: right;
  padding-right: 70px;
}

.interesting-img:after {
  position: absolute;
  display: block;
  width: 100%;
  content: "";
  height: 70%;
  background: #f7f7f7;
  top: 13%;
  left: 0;
  border-radius: 5px;
  z-index: -1;
}

.grown-cont {
  max-width: 470px;
  width: 100%;
}

.grown-cont h1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #333333;
  font-weight: 700;
  margin-bottom: 20px;
}

.grown-cont p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #787878;
  margin: 0;
  text-align: justify;
}

.grown-cont:before {
  position: absolute;
  display: block;
  content: "";
  width: 150px;
  height: 1px;
  background: #787878;
  top: 40%;
  left: -120px;
  z-index: 1;
}

.ractangle-img {
  position: absolute;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  right: 36%;
  top: -130px;
  z-index: -2;
}

.dot-img {
  position: absolute;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  right: 43%;
  bottom: -100px;
  z-index: -2;
  opacity: 0.3;
}

.line-img {
  position: absolute;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  right: 0;
  top: 0;
  opacity: 0.3;
}

.ractangle-img2 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: auto;
  left: 20%;
  top: -90px;
  opacity: 0.5;
}

.interesting-img2 {
  text-align: left;
  padding: 0;
  padding-left: 70px;
}

.labgrown-block {
  padding-bottom: 100px;
}

.flex-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-display2 {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.grown-cont2:before {
  right: -120px;
  left: auto;
}

.image-line-2:before {
  top: 170px;
}

.image-line-3:before {
  top: 45%;
}

.image-line-4:before {
  top: 142px;
}

.image-line-5:before {
  top: 45%;
}

.image-line-6:before {
  top: 43%;
}

.grown-cont2 {
  text-align: right;
}

.polygon-img {
  right: 43%;
  top: -70px;
}

.dot-img2 {
  left: auto;
  top: auto;
  bottom: -70px;
  right: 11%;
}

.cvd-image {
  right: 56%;
}

.created {
  right: -90px;
  top: 140px;
}

.curve-img {
  right: auto;
  bottom: -20px;
  left: 30%;
}

.spining {
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: spining-wheel;
  animation-name: spining-wheel;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

@-webkit-keyframes spining-wheel {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spining-wheel {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hero-about {
  margin: 70px 0;
}

.hero-shape {
  position: relative;
  display: block;
  width: 100%;
  background-image: url("../images/backgroundimage/shape.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 100px 0;
}

.value-box {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 20px;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.value-box .value-cont {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  padding: 40px 0 10px 0;
}

.value-box .value-cont h5 {
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 10px;
}

.value-box .value-cont p {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #787878;
  margin: 0;
  min-height: 73px;
}

.value-box .value-img {
  position: absolute;
  display: block;
  width: 87px;
  height: 87px;
  text-align: center;
  line-height: 87px;
  border-radius: 50%;
  background: #fff;
  top: -45px;
  margin: 0 auto;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  right: 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.value-box:hover .value-img {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.gallery-img {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 5px;
  height: 250px;
}

.gallery-img .plus_btn img {
  height: 250px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.gallery-img .plus_btn {
  position: relative;
  display: block;
  width: 100%;
}

.gallery-img .plus_btn .overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  line-height: 40vh;
  background: rgba(0, 95, 193, 0.85);
  opacity: 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-img .plus_btn .overlay img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  width: 50px;
  height: 50px;
}

.ekko-lightbox.modal {
  z-index: 999999;
}

.gallery-img .plus_btn .overlay img:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.ekko-lightbox-nav-overlay .plus_btn:last-child span {
  text-align: right;
  color: #fff;
  background: #000;
  width: 40px;
  height: 40px;
  display: inline-block;
  flex-grow: initial;
  margin-left: auto;
  padding: 0;
  text-align: center;
  line-height: 40px;
  margin-right: 0;
}
.ekko-lightbox-nav-overlay .plus_btn span {
  text-align: right;
  color: #fff;
  background: #000;
  width: 40px;
  height: 40px;
  display: inline-block;
  flex-grow: initial;
  margin-right: auto;
  padding: 0;
  text-align: center;
  line-height: 40px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}

.gallery-img:hover .plus_btn .overlay {
  opacity: 1;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.styles_lightroom__1X2qE {
  z-index: 99999999 !important;
  position: fixed !important;
}

.carousel-wrapper .carousel-container {
  max-width: 100%;
}

.map iframe {
  border: none;
  width: 100%;
  display: block;
  height: 696px;
  margin-left: 10px;
  height: 100%;
}

button.plus_btn {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.styles_row__2gmnz {
  column-gap: 0 !important;
  display: flex;
  flex-wrap: wrap;
}
.styles_row__2gmnz .styles_column__2r45X {
  margin-bottom: 20px;
  width: 33.3333%;
  padding: 0 10px;
}

.styles_row__2gmnz .styles_column__2r45X img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.map .map-card {
  display: block;
  width: 100%;
  background: #005fc1;
  border: 1px solid #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
}

.map .map-card::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.map .map-card::-webkit-scrollbar-track {
  background: #005fc1;
}

/* Handle */
.map .map-card::-webkit-scrollbar-thumb {
  background: #002e5e;
}

/* Handle on hover */
.map .map-card::-webkit-scrollbar-thumb:hover {
  background: #002e5e;
}

.map .map-card .map-title {
  margin-bottom: 16px;
}

.map .map-card .map-title h2 {
  font-size: 28px;
  line-height: 37px;
  margin: 0;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.map .map-card .area-title {
  margin-bottom: 14px;
}

.map .map-card .area-title h5 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
}

.map .map-card .address-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-bottom: 10px;
}

.map .map-card .address-box .add-icon {
  width: 40px;
}

.map .map-card .address-box .add-cont {
  width: 100%;
}

.map .map-card .address-box .add-cont p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
}

.map .map-card .address-box .add-cont p a {
  color: #fff;
  text-decoration: none;
}

.map .map-card .social-icon ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.map .map-card .social-icon ul li {
  position: relative;
  display: inline-block;
  width: auto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 700;
  margin-right: 14px;
}

.map .map-card .social-icon ul li a {
  color: #fff;
  text-decoration: none;
}

.contact-card {
  max-width: 830px;
  margin: 0 auto;
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
}

.hero-contact {
  margin-bottom: 80px;
}

.contact-title {
  margin: 30px 0 15px 0;
}

.contact-title h2 {
  font-size: 28px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #005fc1;
  font-weight: 700;
  margin-bottom: 0;
}

.cmn-content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.policy-box,
.cmn-content-wrapper {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px 15px;
}

.policy-box .policy-cont,
.cmn-content-wrapper {
  margin-bottom: 40px;
}

.policy-box .policy-cont:last-child {
  margin-bottom: 0;
}

.policy-box .policy-cont h2,
.cmn-content-wrapper h2,
.cmn-content-wrapper h1 {
  font-size: 18px;
  line-height: 22px;
  color: #005fc1;
  font-weight: 700;
  margin-bottom: 10px;
}

.cmn-content-wrapper h2:not(:nth-of-type(1)),
.cmn-content-wrapper h1:not(:nth-of-type(1)) {
  margin-top: 50px;
}

.cmn-content-wrapper strong {
  color: #333333;
}

.policy-box .policy-cont h5,
.cmn-content-wrapper h3,
.cmn-content-wrapper h4,
.cmn-content-wrapper h5 {
  font-size: 14px;
  line-height: 22px;
  color: #787878;
  font-weight: 700;
}

.policy-box .policy-cont p,
.cmn-content-wrapper p,
.policy-box .policy-cont li,
.cmn-content-wrapper li {
  font-size: 14px;
  line-height: 22px;
  color: #787878;
  margin-bottom: 5px;
}

.cmn-content-wrapper p,
.cmn-content-wrapper li {
  margin-bottom: 15px;
  text-align: justify;
}

.policy-box .policy-cont p a,
.cmn-content-wrapper p a,
.policy-box .policy-cont li a {
  font-weight: 500;
  text-decoration: none;
  color: #005fc1;
}

.policy-box .policy-cont ul,
.cmn-content-wrapper ul {
  list-style: disc;
  padding-left: 20px;
}

.policy-box .policy-cont ul li .cmn-content-wrapper ul li {
  line-height: 25px;
}

textarea {
  background: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  border: none;
  padding: 15px !important;
}

/* G-css end */

/* my cart start */
.cart button {
  background: transparent;
  color: #ffff;
  border: none;
}

.cart button:focus {
  outline: none;
}

.mycart-wrapper {
  width: 402px;
  border: 1px solid #ededed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  background-color: #fff;
  z-index: 555;
  position: relative;
}

.cart-header {
  padding: 11px 0px 11px 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.02em;
  background: #f2f2f2;
  color: #005fc1;
}

.purchase-items-container {
  max-height: 400px;
  overflow: auto;
}

.purchase-items {
  padding: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.purchase-items-image {
  max-width: 52px;
}

.purchase-items .my-cart-shape {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #333333;
  margin-left: 9px;
  width: 61%;
}

.purchase-items .purchase-price-qty {
  font-size: 14px;
  line-height: 24px;
  color: #787878;
}

.purchase-items .purchase-price-qty span:nth-of-type(1) {
  font-weight: 700;
  display: inline-block;
}

.purchase-items .purchase-price-qty span:nth-of-type(2) {
  font-weight: 400;
}

.purchase-items .mycart-close i {
  color: #787878;
  font-size: 18px;
  cursor: pointer;
}

.mycart-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 12px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.mycart-total span {
  font-size: 22px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.02em;
}

.mycart-total span:nth-of-type(1) {
  color: #787878;
}

.mycart-total span:nth-of-type(2) {
  color: #005fc1;
}

.goto-cart-btn {
  padding: 0px 12px;
  padding-bottom: 11px;
}

.goto-cart-btn a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  background: #333333;
  width: 100%;
  display: block;
  padding: 12px 0px;
  border-radius: 5px;
  margin-top: 43px;
}

/* my cart end */
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: inherit;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #f7f7f7 !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

.react-toast-notifications__container {
  z-index: 10000000 !important;
}
.call_wrap ul {
  display: flex;
  align-items: center;
  margin: 0;
}
.call_wrap ul li {
  display: inline-block;
  padding: 0 15px;
  border-right: 1px solid #545454;
  margin-right: 15px;
}

.call_wrap ul li a {
  color: #fff;
}

.call_wrap ul li a:hover {
  text-decoration: none;
  color: #63a2e2;
}
.billing-input-box .css-1s2u09g-control {
  background-color: #f7f7f7;
  border-color: transparent;
}

.billing-input-box .css-1s2u09g-control .css-6j8wv5-Input {
  height: 40px;
}
.return_check {
  padding-left: 15px;
}
.return_check input {
  margin-right: 5px;
}
.return_check a {
  color: #005fc1;
}
.cart-summary-box .cart-box ul li a {
  color: #005fc1;
}
.cart-button .cart-btn[disabled] {
  opacity: 0.5;
}
.cart-summary-box .cart-button .cart-btn {
  border: 0;
}
.call_button {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.call_button a.active-link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  display: inline-block;
}

/*# sourceMappingURL=style.css.map */

@media (max-width: 1440px) {
  .shopping-value {
    height: 13vw !important;
  }

  /* Home page start */
  .carousel-container .slick-next {
    right: 15px !important;
  }

  .carousel-container .slick-prev {
    left: 15px !important;
  }

  /* Home end */
}

@media (max-width: 1100px) {
  /* header start */
  .account-nav-wrapper {
    margin-left: 20%;
  }

  /* headet end */
  .shopping-value {
    height: 16vw !important;
  }

  /* footer start */
  .image-mail-btn {
    padding-bottom: 5%;
    flex-wrap: wrap;
  }

  /* footer end */
  .myaccount-tabs .nav {
    min-width: 170px;
  }

  .myaccount-tabs .tab-description {
    max-width: 793px;
    margin-left: 20px;
  }

  .change-password .billing-input-box .input-box {
    width: 100%;
  }

  .produt-singel-box-image img {
    max-width: 150px;
  }

  .produt-singel-box-image {
    height: 176.5px;
  }

  .produt-singel-box-image {
    text-align: center;
  }

  .sortby {
    text-align: left;
    justify-content: start;
  }

  .sort-by-select-innner select {
    min-width: 188px;
  }

  .cart-like-detail-btn {
    flex-wrap: wrap;
    min-width: 198px;
  }

  .large-none {
    margin-bottom: 10px;
  }

  .large-view-size-table-shapw-wrapper {
    max-width: 314px !important;
  }

  .large-view-size-table-shapw-wrapper:after {
    display: none;
  }

  .change-view-btn {
    display: flex;
  }

  /* Stock page start */
  .cart-like-detail-btn a {
    width: 40px;
    height: 40px;
    padding: 10px 10px;
  }

  /* Stock page end */

  /* Product sub page start */
  .linear-line {
    max-width: 797px;
  }

  .color-image-details-single {
    padding: 0px 2px;
  }

  .hover-content-on-image {
    min-width: fit-content;
    max-width: 260px;
  }

  section {
    overflow: hidden;
  }

  .pop-content {
    min-width: 332px;
  }

  .single-button-with-tooltip5.pop-show:before {
    top: 66px;
  }

  .single-button-with-tooltip5.pop-content {
    top: -55px;
  }

  /* Product sub page end*/

  /* Compare page start */
  .fixed-ul {
    max-height: 231px;
  }

  .compare-cmn-part ul {
    min-width: 224px;
  }

  .goto-compare {
    right: 2%;
    bottom: 51%;
  }

  /* Compare page end */
}

@media (min-width: 600px) and (max-width: 800px) {
  .image-mail-btn span {
    width: 40%;
    margin-left: 5%;
    margin-top: auto;
  }

  .cart-summary-px {
    padding-left: 0;
  }

  .image-overlay {
    width: 100%;
  }

  .large-view-wrapper {
    flex-wrap: wrap;
  }

  .large-view-wrapper .cart-like-detail-btn {
    margin: 0 auto;
    margin-top: 10px;
  }

  .large-view-wrapper .price {
    text-align: center;
    margin-bottom: 10px;
  }

  .cart-like-detail-btn-tab-view-list {
    min-width: 300px;
    max-width: 300px;
  }

  .cart-like-detail-btn-tab-view-list a {
    margin-bottom: 10px;
  }

  .shipping-box .shipping-icon {
    width: 6%;
  }

  .cart-summary-box {
    margin-top: 10px;
  }
}

@media (max-width: 800px) {
  /*header start */
  .account-nav-wrapper {
    margin-left: 25%;
  }

  .navbar,
  .wishlist-cart-wrapper {
    padding: 14px 0px;
  }

  .menu-wrapper {
    width: 70%;
  }

  .wishlist > a,
  .cart > a {
    display: flex;
  }

  .navbar-nav .nav-item {
    padding: 10px 20px;
  }

  .navbar {
    border-radius: 6px;
  }

  .home-aboutus {
    padding-top: 5%;
  }

  .serving-client-header {
    width: 89%;
  }

  .home-aboutus-image img {
    border-radius: 50px;
  }

  .serving-client-box {
    min-height: 341px;
  }

  .labgrown-title-container h1 {
    width: 100%;
  }

  /*header end */

  /* footer start */
  footer {
    overflow: hidden;
  }

  .image-mail-btn {
    padding-bottom: 5%;
  }

  .image-mail-btn input {
    margin-left: 0;
    min-width: 340px;
    margin-right: 10px;
  }

  .mail-submit button {
    margin-left: 0px;
  }

  .right-reserved-social p {
    width: 63%;
  }

  .footer-links {
    margin-top: 23px;
    margin-left: 0px;
  }

  .image-mail-btn .footer-image-container {
    max-width: 149px;
  }

  .spacer {
    display: none !important;
  }

  .mail-submit {
    margin-top: 10px;
  }

  /* footer end */
  .carousel-container .slick-next,
  .carousel-container .slick-prev {
    display: none !important;
  }

  .cmn-inner-slider {
    background: #0000007a;
  }

  .diamond-classic-shapes {
    background-size: auto;
  }

  /* my profile  start*/
  .myaccount-tabs .nav {
    min-width: 160px;
  }

  .myaccount-tabs .tab-description {
    max-width: 536px;
    margin-left: 10px;
  }

  .single-order {
    margin-bottom: 25px;
  }

  /* my profile end */

  /*labgrowndiamond page  */
  .created {
    display: none;
  }

  .grown-cont:before {
    display: none;
  }

  .hero-about {
    margin: 25px 0;
  }

  .value-box {
    margin-bottom: 52px;
  }

  .hero-shape {
    padding: 100px 0 25px 0;
  }

  /*labgrowndiamond page  */
  .color-image-details-wrapper {
    flex-wrap: wrap;
  }

  /* out stock page */
  /* .produt-singel-box-image img {
    max-width: 250px;
  } */
  .goto-compare {
    height: 40px;
    width: 40px;
  }

  .produt-singel-box-image {
    text-align: center;
  }

  .sortby {
    text-align: left;
  }

  .sort-by-select-innner select {
    min-width: 188px;
  }

  .cart-like-detail-btn {
    flex-wrap: wrap;
  }

  .large-none {
    margin-bottom: 10px;
  }

  .large-view-size-table-shapw-wrapper {
    max-width: 100% !important;
  }

  .large-view-size-table-shapw-wrapper:after {
    display: none;
  }

  .produt-singel-box-image {
    height: 150px;
  }

  /* out stock page end */

  /* Product sub page start*/
  .carat-size-container {
    padding: 4.5% 10px 14% 10px !important;
  }

  .hover-content-on-image {
    max-width: 260px;
  }

  .shown-image {
    max-width: 453px;
    text-align: center;
    margin: 0 auto;
  }

  .previewer-carousel {
    border-radius: 5px;
    background: #f7f7f7;
    margin: 0 auto;
    max-width: 450px;
  }

  .product-page-table,
  .social-link-drop-list,
  .shipping-details {
    max-width: 100%;
  }

  .linear-line {
    max-width: 671px;
  }

  .color-image-details-wrapper-bottom-titile {
    max-width: 180px;
  }

  .popover {
    min-width: min-content;
    max-width: 200px !important;
  }

  .single-button-with-tooltip button {
    padding: 10px 50px;
  }

  .pop-content {
    min-width: 270px;
  }

  .single-button-with-tooltip4.pop-show:before {
    top: 42px;
  }

  .single-button-with-tooltip4.pop-content {
    top: -34px;
  }

  .single-button-with-tooltip5.pop-show:before {
    top: 89px;
  }

  .single-button-with-tooltip5.pop-content {
    top: -80px;
  }

  /* About us page  start*/
  .about-img img {
    border-radius: 45px;
  }

  .value-box {
    min-height: 236px;
  }

  /* About us page  end*/
  .cart-summary-box .cart-button .cart-btn {
    font-size: 16px;
  }

  .shopping-value {
    height: 21vw !important;
  }

  /* Shopping-cart start */
  .shipping-box .shipping-icon {
    margin-right: 10px;
  }

  .goto-cart-image img {
    max-width: 127px;
  }

  .cmn-diamond-box-inner-wrapper {
    margin-bottom: 15px;
  }

  /* Shopping-cart end */
  /* Check out page start */
  .shape-boxes {
    align-items: center;
  }

  .shape-boxes .shape-box {
    align-items: center;
  }

  .shape-boxes .shape-box .shape-image {
    width: 60%;
  }

  .shape-boxes .shape-box .shape-cont {
    width: 100%;
    margin-left: 5%;
  }

  .single-address-contianer {
    width: 100% !important;
    /* margin-bottom: 10px; */
  }

  .border-rights {
    border: none !important;
  }

  .cart-basket {
    top: -6px !important;
    right: -28px !important;
  }

  .back-btn-container {
    max-width: 100% !important;
  }

  /* Check out page end */
}

@media (max-width: 600px) {
  section {
    overflow: hidden;
  }

  /* header start */
  .navbar,
  .wishlist-cart-wrapper {
    padding: 5px 0px;
  }

  .navbar-toggler {
    margin-top: 5px;
  }

  .account-nav-wrapper {
    position: relative;
    margin-left: 0;
  }

  .logo-container {
    position: fixed;
    left: 36%;
    top: 0%;
    z-index: 55;
    width: 76px;
  }

  .header,
  .dummy-div {
    height: 36px;
  }

  .mycart-wrapper {
    width: 340px;
  }

  .count {
    left: 6px;
  }

  .cart-count-image-wrapper i {
    max-width: 14px;
  }

  .cartclose {
    cursor: pointer;
  }

  /* header end */
  /* footer start */

  .serving-client-box {
    min-height: 341px;
  }

  .image-mail-btn input {
    margin-left: 0;
    min-width: 327px;
  }

  .right-reserved-social p {
    width: 63%;
    font-size: 10px;
  }

  .mail-submit button {
    margin-left: 0px;
  }

  /* footer end */
  .carousel-container {
    padding-left: 15px;
  }

  .cmn-slider {
    padding: 11% 15px 14% 0px;
  }
  /* .cmn-slider1 {
    padding: 11% 15px 14% 0px;
  } */

  .cmn-inner-slider span:nth-of-type(1) {
    font-size: 25px;
  }

  .cmn-inner-slider h1 {
    font-size: 25px;
    line-height: 41px;
  }

  .carousel-container .slick-dots {
    bottom: 3%;
  }

  .cmn-section-titile {
    font-size: 35px;
    line-height: 43px;
  }

  .mb-lab-grown-diamond {
    margin-bottom: 0px;
  }

  .cmn-learnmore-btn-grey {
    margin-bottom: 10px;
  }

  .serving-client-box {
    margin-bottom: 15px;
  }

  .footer-links {
    margin-top: 15px;
    margin-left: 0px;
  }

  .image-mail-btn {
    flex-flow: column;
    padding-bottom: 15%;
  }

  .image-mail-btn span {
    width: 100%;
  }

  /*labgrowndiamond page start  */
  .lab-grown-dimaond-page {
    padding: 25px 0px 5.3% 0;
  }

  .flow-innner {
    margin-left: 0px;
    margin-top: 10px;
  }

  .labgrown-block {
    padding-bottom: 50px;
  }

  .grown-cont2 {
    margin-bottom: 10px;
  }

  .grown-cont p {
    text-align: justify;
  }

  .hero-shape {
    padding: 72px 0 1px 0;
  }

  .card-header {
    padding: 5.5px 0px;
  }

  .acc-header-common span {
    text-align: left;
  }

  .acc-header-common span {
    font-size: 15px;
  }

  /*labgrowndiamond page  end */

  /* contact us page start */
  .map .map-card {
    left: 0;
  }

  .billing-input-box .input-box {
    width: 100%;
    margin-bottom: 15px;
  }

  .billing-input-box {
    flex-wrap: wrap;
  }

  .billing-input-box .input-box2 {
    padding-left: 1px;
  }

  .billing-input-box {
    padding: 0px 20px;
  }

  textarea {
    width: 100%;
  }

  /* my profile  start*/
  .myaccount-tabs .nav {
    min-width: 160px;
    width: 100%;
    margin-bottom: 15px;
  }

  .myaccount-tabs .tab-description {
    margin-left: 0px;
  }

  /* my profile  end*/

  /* address start */

  .border-rights {
    border-right: none;
    border-bottom: 1px solid #ededed;
    margin-bottom: 15px;
  }

  .address-details ul li {
    max-width: 100%;
  }

  .address-details ul li span:nth-of-type(2) {
    margin-left: 0;
  }

  .address-details ul li span:nth-of-type(1) {
    margin-bottom: 5px;
  }

  .address-box2 input,
  .address-box2 select {
    margin-left: 0px;
  }

  .address-box2 ul li {
    flex-flow: column !important;
  }

  .single-address-contianer,
  .address-details ul,
  .name-with-edit-delete {
    padding: 0px;
  }

  /* address end */
  .sign-in-input .input-box {
    width: 100% !important;
  }

  /* contact us page end */
  .title-margin {
    margin-left: 0;
    text-align: center;
  }

  /* Stock page start */
  .view-shortby {
    margin-top: 14px;
  }

  .change-view-btn-wrapper {
    display: none;
  }

  .container-2 {
    position: relative;
  }

  .mobile-filter {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    outline: none;
    border: none;
    display: inline-flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border: 2px solid #ededed;
  }

  .mobile-filter i {
    color: #787878;
    font-size: 18px;
  }

  .mobile-view-slide {
    position: absolute;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    z-index: 55;
    top: 62px;
  }

  .mobile-view-toggle {
    transform: translateX(0%);
  }

  .large-view-size-table-shapw-wrapper {
    max-width: 100% !important;
  }

  .image-with-tooltip2 {
    display: none;
  }

  .carat-size-container {
    padding: 4.5% 10px 4.5% 10px !important;
  }

  .big-container h2 {
    margin-bottom: 15px;
  }

  .diamond-details-titile {
    font-size: 25px;
  }

  .diamond-details-sub-table .diamond-details-sub-table-header {
    margin-top: 10px;
  }

  .color-image-details-wrapper {
    margin-top: 15px;
  }

  .color-image-details-wrapper-row {
    justify-content: center;
  }

  .sortby {
    flex-wrap: wrap;
    justify-content: start;
  }

  .total {
    margin-left: 20px;
    padding-right: 0px;
    border: none;
  }

  .sortby label {
    padding-left: 0px;
  }

  .sort-by-select-innner {
    margin-top: 10px;
  }

  .produt-singel-box-image img {
    max-width: 187px;
  }

  /* Stock page end */

  /* Product sub page start*/
  .color-image-details-wrapper-row {
    display: flex;
    flex-wrap: wrap;
  }

  .color-image-details-wrapper-column1,
  .color-image-details-wrapper-column2 {
    margin-bottom: 15px;
  }

  .produt-page-like-wishlistbtn {
    display: flex;
  }

  .single-button-with-tooltip button {
    padding: 8px 10px;
  }

  .pop-content {
    min-width: 228px;
  }

  .button-with-tooltip {
    margin-top: 10px;
  }

  .single-button-with-tooltip4.pop-show:before {
    top: 65px;
  }

  .single-button-with-tooltip4.pop-content {
    top: -57px;
  }

  .single-button-with-tooltip5.pop-show:before {
    top: 106px;
  }

  .single-button-with-tooltip5.pop-content {
    top: -100px;
  }

  .last-container-white {
    padding-bottom: 10%;
  }

  .produt-page-like-wishlistbtn a {
    min-width: 148px;
  }

  /* Product sub page end*/
  /* About us page start */
  .value-box {
    min-height: 180px;
  }

  /* About us page end */

  /* header my cart start */
  .go-to-cart {
    position: absolute;
    right: -42px;
    display: none;
    top: 31px;
  }

  /* header my cart end */

  /* shopping cart start */

  .goto-cart-image img {
    /* max-width: 68px; */
    max-width: 105px;
  }

  .large-view-size-table-shapw-wrapper {
    margin-left: 10px;
  }

  .shap-rating span:nth-of-type(1) {
    font-size: 16px;
  }

  .large-view-wrapper .shopping-value .price {
    font-size: 16px;
  }

  .shopping-value {
    width: 14%;
    height: 55vw !important;
  }

  .shippping-address-choice {
    flex-wrap: wrap;
    height: auto !important;
    padding: 10px 0px 0px 10px !important;
  }

  .shippping-address-choice .billing-input-box {
    padding: 0px 7px !important;
  }

  .payment-input .radio-payemt {
    margin-bottom: 12px;
    margin-right: 39px;
  }

  .billing-address {
    flex-wrap: wrap;
  }

  .billing-address-btn {
    margin-top: 8px;
  }

  .payment-card {
    padding: 13px 0px;
  }

  .payment-card ul li a {
    display: inline-flex;
  }

  .cart-summary-box .cart-box ul li.carttotal {
    margin-bottom: 0;
  }

  /* shopping cart end */
  /* Compare page */
  .compare-fixedpart-inner ul {
    max-width: 140px !important;
    min-width: 140px !important;
  }

  .compare-fixedpart {
    padding: 0;
  }

  .compare-cmn-part ul {
    min-width: 145px;
  }

  .compare-scrollpart .compare-scroll-inner:not(:nth-last-of-type(1)) {
    margin-right: 12px;
  }

  .compare-cmn-part ul .image-li {
    min-height: 142px;
  }

  .payment-info-billing-shipping-wrapper {
    flex-wrap: wrap;
  }
}

.wishlist-container {
  background: none;
}

.single-button-with-tooltip {
  position: relative;
}

.active-scroll {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.mode-on-off-wrapper .switch-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border: 1px solid #005fc1; */
  border-radius: 10px;
  font-size: 18px;
  line-height: 21px;
  width: fit-content;
}

.mode-on-off-wrapper .switch-mode span {
  padding: 7px 16px;
  border-radius: 7px;
  background: gainsboro;
  margin-right: 7px;
}

.mode-on-off-wrapper .switch-mode .sleep-mode-off {
  background: #005fc1;
  color: #fff;
}

.mode-on-off-wrapper .switch-mode .sleep-mode-on {
  background: #005fc1;
  color: #fff;
}

.circle-shadow {
  border: 2px solid #005fc1;
  -webkit-box-shadow: 0px 4px 14px rgb(0 0 0 / 15%);
  box-shadow: 0px 4px 14px rgb(0 0 0 / 15%);
}

.circle-shadow i {
  color: #005fc1;
}

.diamond-loader {
  position: absolute;
  top: 68px;
  left: 15px;
  right: 15px;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: rgba(247, 247, 247, 0.75);
}

.loading-loader {
  padding: 27px;
  position: sticky;
  top: 258px;
  margin-top: 78%;
}

.logo-blink img {
  animation: blink 1s infinite alternate;
}

.navbar-toggler b {
  color: #fff;
  font-size: 12px;
  margin-left: 25px;
  margin-top: -7px;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.error-box {
  justify-content: center;
}

.error-box-inner h3 {
  font-size: 20px !important;
}

.tooltips {
  position: relative;
}

.tooltips .tooltiptext {
  visibility: hidden;
  position: absolute;
  min-width: 100px;
  max-width: 120px;
  background-color: #333333;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  bottom: -38px;
  font-size: 12px;
  white-space: nowrap;
}

.tooltips-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333333 transparent;
}

.tooltips:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.goto-compare-tooltip {
  transform: rotate(180deg);
}

.tooltips .tooltip-tops::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-tops {
  right: -38px;
  bottom: 41px !important;
}

.color-range-slider .rc-slider-mark span {
  white-space: nowrap;
}

.large-view-size-table-shapw-wrapper-alignment {
  max-width: 321px;
  width: 100%;
  margin-left: 20px;
}

.top-id-none {
  display: none !important;
}

.exisiting-address-dropdow {
  width: 100% !important;
}

.checkout-input-box-exisitng {
  background: #ffffff;
  border: 1px solid #ededed;
  border-bottom: none;
}

.checkout-input-box-exisitng .cart-title {
  background: transparent;
}

.existing-address-after-select label {
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #333333 !important;
  margin-bottom: 5px;
  font-weight: 600;
  margin-bottom: 4px;
}

.existing-address-after-select {
  color: #787878;
  margin-top: 10px;
}

.existing-address-after-select-inner {
  color: #787878;
  background: #f2f2f29c;
  border-radius: 5px;
  font-size: 14px;
  padding: 15px 15px;
  line-height: 22px;
}

.existing-address-after-select-inner-number {
  margin-top: 5px;
}

.payment-info-subtiltle {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #005fc1;
  margin: 0;
  font-weight: 700;
}

.contact-us-submit {
  margin-bottom: 25px;
}

.use-different-address {
  background: transparent !important;
}

.addresses-title {
  padding-left: 12px;
}

.my-order-title {
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.my-order-title i {
  float: right;
}

.payment-info-billing-shipping-wrapper {
  display: flex;
  align-items: self-start;
  width: 100%;
}

.my-order-wrapper-table .table td,
.my-order-wrapper-table .table th {
  vertical-align: middle;
  white-space: nowrap;
}

.my-order-wrapper-table {
  max-width: 870px;
  width: 100%;
  overflow: auto;
}

.order-details-total-wapper {
  text-align: right;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  border-top: 1px solid #dee2e6;
}

.my-order-wrapper-table .table {
  margin-bottom: 0;
}

.order-details-total-row {
  border-top: 1px solid #dee2e6;
  padding: 7px 15px 7px 0px;
  display: inline-block;
}

.order-details-total-row:nth-of-type(1) {
  border-top: none;
}

.order-details-total-row span {
  min-width: 122px;
  display: inline-block;
}

.order-details-total-row span:nth-of-type(1) {
  text-align: left;
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .goto-compare {
    right: 2px;
  }
}

.video-image-btn {
  text-align: center;
}

.video-image-btn i {
  font-size: 27px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #dedcdc;
  border-radius: 5px;
  box-shadow: 0px 6px 14px rgb(0 0 0 / 25%);
  margin-top: 15px;
  transition: all 0.5 ease;
}

.video-image-btn i:nth-of-type(1),
.video-image-btn i:nth-of-type(2) {
  margin-right: 10px;
}

.video-image-active {
  color: #005fc1;
}

.iframe {
  width: 510px;
  height: 510px;
  border-radius: 5px;
}

.previewer-carousel {
  min-height: 510px;
  height: 516px;
}

.previewer-carousel img {
  border-radius: 5px;
  max-width: 510px;
}

.certificate_link {
  color: #333;
}

.reportid-img {
  color: #005fc1 !important;
}
@media (max-width: 991px) {
  .carousel-wrapper {
    padding-bottom: 40px;
  }
  .carousel-container .slick-dots {
    bottom: -16px;
  }
}
@media (max-width: 600px) {
  .mobile-iframe {
    width: 360px;
    overflow: auto;
  }
}

.forgote-password.signup-link {
  width: 100%;
  padding: 5px 20px;
}

.create-account-link {
  margin: 35px 0 0 0;
  display: inline-block;
}

.text-link {
  margin: 35px 0 0 0;
  text-align: center;
}

/* remove after final */
.custom-demo {
  margin-left: 20px;
  width: 50%;
}

.large-view-wrapper .shopping-value .price {
  margin-left: 42px;
}

/* product details */
.product-page-report-id {
  line-height: 13px;
}

.product-page-report-id span:nth-of-type(1),
.product-page-report-id span:nth-of-type(2) {
  display: inline-block;
}

.dimond-description p {
  margin-bottom: 6px;
}

.dimond-description p {
  margin-bottom: 4px;
}

.table-titile-with-image img {
  max-width: 20px;
}

.table-parice {
  font-size: 30px;
  margin: 20px 0px 20px 0px;
}

.product-page-table {
  padding: 13px 20px 13px 20px;
}

.produt-page-like-wishlistbtn {
  margin: 13px 0px;
}

.igi-logo2 {
  margin: 10px 0px 0px 0px;
}

.produt-page-like-wishlistbtn {
  margin: 13px 0px 13px 0px;
}

.shipping-details {
  margin-top: 0px;
}

/* back btn */
.back-btn-container {
  text-align: right;
  max-width: 475px;
}

.backbtn {
  display: inline-flex !important;
  align-items: center;
  margin-right: 5px;
  background: #005fc1;
  color: #fff;
  padding: 6px 15px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid transparent;
}

.backbtn:hover {
  background: #005fc1;
  color: #fff;
  text-decoration: none;
}

.backbtn i {
  margin-right: 5px;
  font-size: 20px;
}

/*  chech out*/
.chechout-payment-address-title {
  padding: 10px 20px 10px 20px;
  color: #333333;
  font-weight: bold;
  border-bottom: 1px solid #ededed;
}

.strip-payment-title {
  padding: 9.5px 20px 9.5px 20px;
  color: #333333;
  font-weight: bold;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}

#full-stars-example-two {
  margin-top: 19px;
}

#full-stars-example-two i {
  font-size: 20px;
  color: darkgray;
  margin-right: 16px;
  cursor: pointer;
}

.icon-active {
  color: #005fc1 !important;
}

.cart-item-infoshap-rating {
  flex-flow: column;
}

.produt-page-like-wishlistbtn a:nth-of-type(1) img {
  height: 22px;
}

.reportid-img {
  margin-top: 14px;
}

.static-page-small-title {
  color: #787878;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.static-page-table {
  width: 35%;
}

@media (max-width: 575px) {
  .stock-section-wrapper {
    position: relative;
  }
  .mobile-view-slide {
    position: fixed;
    top: 0;
    left: -100%;
    padding: 0;
  }
  .mobile-view-slide.mobile-view-toggle {
    left: 0;
    z-index: 9999999999;
    height: 100vh;
    overflow: auto;
  }
  .side-panel-filter {
    height: 100%;
  }
  .close_btn {
    width: 30px;
    height: 30px;
    border: 1px solid #005ec1;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
  }
  .close_btn i {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .map .map-card {
    max-width: 500px;
    margin: 0 auto 30px;
  }

  .map iframe {
    margin-left: 0;
  }

  .logo-container {
    width: 150px;
  }
  .account .dropdown-item {
    background-color: #333;
  }
  .carousel-wrapper {
    padding-bottom: 40px;
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .logo-container {
    left: 0;
  }
  .header {
    height: 50px;
  }

  .cmn-slider1 {
    padding: 100% 15px 48% 15px;
    background-image: url("../images/backgroundimage/media.png");
  }
  .cmn-slider2 {
    padding: 30% 15px 20% 15px;
    background-size: contain;
  }
  .dummy-div {
    height: 50px;
  }
}

@media (max-width: 786px) {
  .static-page-table {
    width: 100%;
  }
}

.static-page-table th {
  color: #787878;
  font-weight: 800;
}

.static-page-table td {
  color: #787878;
}

.labgrownstaicpage {
  text-align: center;
  margin-top: 44px;
}

.labgrownstaicpage img {
  max-width: 800px;
}

.c4-diamonds-staticpage {
  margin: 35px 0px 30px 0px;
}

@media (max-width: 600px) {
  .logo-container {
    left: 36%;
  }
  .logo-container {
    width: 100px;
  }
  .header {
    height: 39px;
  }
  .map .map-card {
    top: 100%;
  }
  .carousel-container {
    padding-left: 0;
  }
  .cart-count-image-wrapper i {
    font-size: 14px;
  }
  .cart-basket {
    top: -2px !important;
    right: -18px !important;
    width: 15px;
    height: 15px;
    line-height: 15px;
  }
  .navbar-nav .nav-link {
    font-size: 14px;
  }
  .carousel-wrapper {
    padding-top: 10px;
  }
  .dummy-div {
    height: 39px;
  }
}
@media (max-width: 481px) {
  .cmn-sub-title {
    font-size: 20px;
    line-height: 26px;
  }
  .cmn-section-titile {
    font-size: 24px;
    line-height: 32px;
  }
  .cart-summary-box .cart-box ul li {
    height: auto;
  }
  .cart-summary-box .cart-box ul li span.tax_span {
    font-size: 13px;
    line-height: 19px;
    text-align: left;
    padding: 10px 0;
  }
}

@media (max-width: 381px) {
  .vertical-line {
    margin: 0px 10px;
  }
  .logo-container {
    width: 70px;
  }
}
