.load-more button {
  width: 100%;
  margin-top: -10px;
}
.load-more button {
  padding: 12px 20px;
}

.c-three-dots-loader {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -10px 24px 20px;
  animation-fill-mode: both;
  animation: three-dots-loader-animation 2s infinite ease-in-out;
  animation-delay: -0.16s;
  color: #fff;
}
.c-three-dots-loader:before,
.c-three-dots-loader:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  animation: three-dots-loader-animation 2s infinite ease-in-out;
  border-radius: 50%;
}
.c-three-dots-loader:before {
  left: -15px;
  animation-delay: -0.32s;
}
.c-three-dots-loader:after {
  left: 15px;
}
@keyframes three-dots-loader-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 20px 0 -24px;
  }
  40% {
    box-shadow: 0 20px 0 0;
  }
}
/* .loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
} */
